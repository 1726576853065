import React from 'react';

import isEmpty from '@src/utils/isEmpty';

import { UseCarouselIndicatorAnimationParamTypes, UseCarouselIndicatorAnimationReturnTypes } from './hooksTypes';

const useCarouselIndicatorAnimation =
  ({}: UseCarouselIndicatorAnimationParamTypes): UseCarouselIndicatorAnimationReturnTypes => {
    const indicatorItemsRef = React.useRef<(HTMLDivElement | null)[]>([]);

    const handleAddIndicatorRef = (el: HTMLDivElement) => {
      if (!isEmpty({ value: el }) && !indicatorItemsRef.current.includes(el)) indicatorItemsRef.current.push(el);
    };

    React.useEffect(() => {}, []);

    return { handleAddIndicatorRef };
  };

export default useCarouselIndicatorAnimation;
