// =============================================
//         BASE
// =============================================

import { ButtonTopicItemTypes } from './contentful.types';

export type VideoTypes = 'HTML Video' | 'Vimeo' | 'YouTube';

// =============================================
export type PagePropTypes = {
  params: {
    langAndSlug: [string | undefined, string];
  };
};

export type CarouselCardsTypes = {
  buttons: ButtonTopicItemTypes[];
  icon: string;
  pretitle: string;
  url: string;
  clinicTitle?: string;
  title: string;
  description?: string | React.ReactNode;
  carouselRef?: React.Ref<HTMLDivElement>;
};

/* eslint-disable */
export enum TailwindBreakPoints {
  xsm = 425,
  sm = 500,
  md = 768,
  lg = 1024,
  xl = 1280,
  _2xl = 1444,
  _3xl = 1536,
  _4xl = 1632,
}
/* eslint-enable */

// =============================================
//         SESSION STORAGE TYPES
// =============================================
export type SessionStorageKeyTypes = 'categorySlug';
export type SessionStorageValueTypes = any; // Add more types

export type GetSessionStorageParamTypes = {
  key: SessionStorageKeyTypes;
  defaultValue: SessionStorageValueTypes;
};

export type SessionStorageParamTypes = {
  key: SessionStorageKeyTypes;
  value: SessionStorageValueTypes;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type GetSessionStorageReturnTypes = any;

// =============================================
//                   CSS TYPES
// =============================================
export type ObjectFitTypes = 'fill' | 'contain' | 'cover' | 'none' | 'scale-down';
