import React from 'react';

import { HandleJumpToSlideParamsTypes, UseCarouselAnimationReturnTypes } from './hooksTypes';

const useCarouselAnimation = ({ overrideMaxIndex }: { overrideMaxIndex?: number }): UseCarouselAnimationReturnTypes => {
  const carouselRef = React.useRef<HTMLDivElement>(null);
  const containerRef = React.useRef<HTMLDivElement>(null);
  const carouselItemsRef = React.useRef<HTMLDivElement[]>([]);
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [dragging, setDragging] = React.useState(false);
  const [maxIndex, setMaxIndex] = React.useState(overrideMaxIndex || 0);

  const calculateShiftAmount = (index: number) => {
    if (carouselItemsRef.current.length === 0) {
      return 0;
    }
    const computedStyle = window.getComputedStyle(carouselItemsRef.current[0]);
    const cssCarouselMarginRight = computedStyle.getPropertyValue('margin-right');
    const cssCarouselWidth = computedStyle.getPropertyValue('width');
    const itemCarouselWidth = parseFloat(cssCarouselWidth);
    const itemRightMargin = parseFloat(cssCarouselMarginRight);
    return (itemCarouselWidth + itemRightMargin) * index;
  };

  const animateCarousel = (shiftAmount: number) => {
    if (carouselRef.current) {
      carouselRef.current.style.transition = 'transform 0.58s ease-out';
      carouselRef.current.style.transform = `translateX(${-shiftAmount}px)`;
    }
  };

  const handleAddCarouselItemsToRef = (el: HTMLDivElement) => {
    if (el && !carouselItemsRef.current.includes(el)) {
      carouselItemsRef.current.push(el);
      if (!overrideMaxIndex) {
        calculateMaxIndex();
      }
    }
  };

  const calculateMaxIndex = () => {
    if (carouselItemsRef.current.length === 0 || !containerRef.current) {
      setMaxIndex(0);
      return;
    }

    const containerWidth = containerRef.current.offsetWidth; // Use container's actual width instead of window's width
    const adjustedWidth = containerWidth + (window.innerWidth - containerWidth) / 2;
    const totalItemsWidth = carouselItemsRef.current.reduce((acc, item) => {
      const computedStyle = window.getComputedStyle(item);
      const itemWidth = parseFloat(computedStyle.getPropertyValue('width'));
      const itemMarginRight = parseFloat(computedStyle.getPropertyValue('margin-right'));
      return acc + itemWidth + itemMarginRight;
    }, 0);

    // Calculate how many full items fit within the visible width of the container
    const itemsVisibleInViewport = Math.floor(adjustedWidth / (totalItemsWidth / carouselItemsRef.current.length));

    // Adjust max index to ensure the last item is fully visible
    const maxIndexCalculated = Math.max(0, carouselItemsRef.current.length - itemsVisibleInViewport);

    setMaxIndex(maxIndexCalculated);
  };

  const handleShowNextItem = () => {
    if (maxIndex <= 0) return; // Disable if maxIndex is not greater than 0
    const newIndex = Math.min(activeIndex + 1, maxIndex);
    setActiveIndex(newIndex);
    const shiftAmount = calculateShiftAmount(newIndex);
    animateCarousel(shiftAmount);
  };

  const handleShowPreviousItem = () => {
    if (maxIndex <= 0) return; // Disable if maxIndex is not greater than 0
    const newIndex = Math.max(activeIndex - 1, 0);
    setActiveIndex(newIndex);
    const shiftAmount = calculateShiftAmount(newIndex);
    animateCarousel(shiftAmount);
  };

  const handleJumpToSlide = ({ index }: HandleJumpToSlideParamsTypes) => {
    return () => {
      if (index > maxIndex || index < 0 || carouselItemsRef.current.length <= 1 || maxIndex <= 0) return;
      setActiveIndex(index);
      const shiftAmount = calculateShiftAmount(index);
      if (carouselRef.current) {
        carouselRef.current.style.transition = 'transform 0.58s ease-out';
        carouselRef.current.style.transform = `translateX(${-shiftAmount}px)`;
      }
    };
  };

  React.useEffect(() => {
    let startX = 0;
    let currentX = 0;
    let diffX = 0;
    let shiftAmount = 0;
    let isDragging = false;
    let clickTimer: number | null = null;

    const handleDragStart = (event: MouseEvent | TouchEvent) => {
      if (carouselItemsRef.current.length <= 1 || maxIndex <= 0) return;
      startX = 'touches' in event ? event.touches[0].clientX : event.clientX;
      if (carouselRef.current) {
        carouselRef.current.style.transition = 'none';
        shiftAmount = calculateShiftAmount(activeIndex);
      }
      isDragging = true;
      clickTimer = window.setTimeout(() => {
        setDragging(true);
      }, 200);
    };

    const handleDragMove = (event: MouseEvent | TouchEvent) => {
      if (!isDragging) return;
      currentX = 'touches' in event ? event.touches[0].clientX : event.clientX;
      diffX = currentX - startX;
      if (carouselRef.current) {
        carouselRef.current.style.transform = `translateX(${diffX - shiftAmount}px)`;
      }
      if (Math.abs(diffX) > 10) {
        if (clickTimer) {
          clearTimeout(clickTimer);
          clickTimer = null;
        }
        setDragging(true);
      }
    };

    const handleDragEnd = () => {
      if (!isDragging) return;
      isDragging = false;
      if (clickTimer) {
        clearTimeout(clickTimer);
        clickTimer = null;
      }
      setDragging(false);
      const itemWidth = calculateShiftAmount(1);
      let newActiveIndex = activeIndex;
      if (diffX < -50 && activeIndex < maxIndex) {
        newActiveIndex = activeIndex + 1;
      } else if (diffX > 50 && activeIndex > 0) {
        newActiveIndex = activeIndex - 1;
      }
      setActiveIndex(newActiveIndex);
      animateCarousel(itemWidth * newActiveIndex);
    };

    const addDragEvents = (el: HTMLDivElement) => {
      el.addEventListener('mousedown', handleDragStart);
      el.addEventListener('touchstart', handleDragStart);
      el.addEventListener('mousemove', handleDragMove);
      el.addEventListener('mouseup', handleDragEnd);
      el.addEventListener('mouseleave', handleDragEnd);
      el.addEventListener('touchmove', handleDragMove);
      el.addEventListener('touchend', handleDragEnd);
      el.addEventListener('dragstart', (e) => e.preventDefault());
    };

    if (containerRef.current) {
      addDragEvents(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        const container = containerRef.current;
        container.removeEventListener('mousedown', handleDragStart);
        container.removeEventListener('touchstart', handleDragStart);
        container.removeEventListener('mousemove', handleDragMove);
        container.removeEventListener('mouseup', handleDragEnd);
        container.removeEventListener('mouseleave', handleDragEnd);
        container.removeEventListener('touchmove', handleDragMove);
        container.removeEventListener('touchend', handleDragEnd);
      }
    };
  }, [activeIndex, maxIndex]);

  React.useEffect(() => {
    if (!overrideMaxIndex) {
      calculateMaxIndex();
      window.addEventListener('resize', calculateMaxIndex);
      return () => {
        window.removeEventListener('resize', calculateMaxIndex);
      };
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    carouselRef,
    containerRef,
    carouselItemsRef,
    handleAddCarouselItemsToRef,
    activeIndex,
    handleShowNextItem,
    handleShowPreviousItem,
    handleJumpToSlide,
    maxIndex,
    dragging,
  };
};

export default useCarouselAnimation;
